import { takeEvery, put, call } from 'redux-saga/effects';
import { 
    LOAD_APP_DATA, 
    setTitle, 
    setGroupsList,
    setCardsList,
    setAppDataLoaded,
    setFormatsList,
    setShelves,
    setHeroData,
    setShelvesDataLoaded,
    setAllCardsLoaded,
} from '../store/app/actions';
import axios from 'axios'
import { 
    setCurrentCategories,
    setCardCategories,
} from '../store/filter/actions';
import getFormats from '../lib/array/getFormats';

var host_live = 'https://repos-api.rms2srv.de/';
var local_host = 'http://localhost:8111/';
var local = false;

function getHost(){
    if (local) {
        return local_host;
    } else {
        return host_live;
    }
}

function fetchData() {
    return axios.get(getHost() + 'reposapi/v2/portal/sikh/')
        .then(respose => {
            return respose.data;
        })
}

// function fetchQuantityOfCategories(slug) {
//     return axios.get(`https://repos-api.rms2srv.de/reposapi/v2/portal/sikh/category/${slug}/1`)
//         .then(respose => {
//             return respose.data.info.total;
//         })
// }

// function fetchReposByCategories(slug, id) {
//     return axios.get(`https://repos-api.rms2srv.de/reposapi/v2/portal/sikh/category/${slug}/all`)
//         .then(respose => {
//             let cards = respose.data.repos;
//             for(let card of cards) {
//                 card.category_id = id;
//             }
//             return cards;
//         })
// }

function makeCategoriesList(repos) {
    return repos.map(card => {
        let res = {}
        if(card.categories.length > 0){
            res.categories = card.categories.map(categorie => categorie.id)
        }
        else{
            res.categories = []
        }
        if(card.format_id){
            res.format = card.format_id
        }
        else{
            res.format = null
        }
        if(card.type.type){
            res.type = card.type.type
        }else{
            res.type = ""
        }
        return res
    })
}

function fetchAllRepos() {
    return axios.get(getHost() + 'reposapi/v2/portal/sikh/all')
        .then(respose => {
            let cards = respose.data.repos;
            return cards;
        })
}

export function* workerLoadAppData() {
    const data = yield call(fetchData);
    // Todo add condition if only one portal exist
    if(data.groups[0].categories.length === 1){
        yield put(setCurrentCategories([data.groups[0].categories[0].id]));
    }

    if(data.shelves.length !== 0) {
        yield put(setShelves(data.shelves))
    }

    if(data.hero) {
        yield put(setHeroData(data.hero))
    }
    
    yield put(setShelvesDataLoaded());
    
    // for(let group of data.groups) {
    //     for(let category of group.categories) {
    //         category.length = yield fetchQuantityOfCategories(category.slug);
    //     }
    // }

    yield put(setTitle(data.portal.name));
    yield put(setGroupsList(data.groups));

    yield put(setAppDataLoaded());

    let cards = yield fetchAllRepos();
    yield put(setCardsList(cards));
    yield put(setFormatsList(getFormats(cards)));

    yield put(setCardCategories(makeCategoriesList(cards)));

    yield put(setAllCardsLoaded());


}

export function* watchLoadAppData() {
    yield takeEvery(LOAD_APP_DATA, workerLoadAppData)
}